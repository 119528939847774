import { useMediaQuery } from "@material-ui/core";
import {
  FocusEventHandler,
  RefObject,
  useCallback,
  useEffect,
  useRef,
} from "react";

type Options = {
  inputRef: RefObject<HTMLInputElement | HTMLTextAreaElement | null>;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

export const useInputHandlersForIME = ({ inputRef, onBlur }: Options) => {
  const isMobile = useMediaQuery("(max-width: 550px)");
  const composing = useRef(false);

  // NOTE: avoid IME issue
  // see: https://vrize.slack.com/archives/CJLJFFM26/p1587988428103700
  const handleBlur = useCallback<NonNullable<typeof onBlur>>(
    (e) => {
      onBlur?.(e);
      const relatedTarget = e.relatedTarget || document.activeElement;
      if (!isMobile) return;
      if (!relatedTarget) return;
      if (!composing) return;
      if (
        relatedTarget instanceof HTMLInputElement ||
        relatedTarget instanceof HTMLTextAreaElement
      ) {
        const handler = (inputEvent: Event) => {
          inputEvent.preventDefault();
          relatedTarget.removeEventListener("beforeinput", handler);
          setTimeout(() => {
            relatedTarget.select();
          }, 0);
        };
        relatedTarget.addEventListener("beforeinput", handler);
      }
    },
    [isMobile, onBlur]
  );

  useEffect(() => {
    const input = inputRef.current;
    const handleCompositionStart = () => {
      composing.current = true;
    };
    const handleCompositionEnd = () => {
      composing.current = false;
    };
    input?.addEventListener("compositionstart", handleCompositionStart);
    input?.addEventListener("compositionend", handleCompositionEnd);
    return () => {
      input?.removeEventListener("compositionstart", handleCompositionStart);
      input?.removeEventListener("compositionend", handleCompositionEnd);
    };
  }, [inputRef]);

  return { handleBlur };
};
