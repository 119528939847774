import Chip from "@material-ui/core/Chip";
import CancelIcon from "@material-ui/icons/Cancel";
import { makeStyles } from "@material-ui/styles";
import { useFormikContext } from "formik";
import * as React from "react";

import {
  FormikSelectField,
  Props as FormikSelectFieldProps,
} from "./FormikSelectField";

export type Props = Omit<FormikSelectFieldProps, "renderValue">;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const useStyles = makeStyles({
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 3,
  },
});

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const FormikMultipleSelectField: React.FC<Props> = ({
  name,
  label,
  options,
  ...formikSelectFieldProps
}) => {
  const classes = useStyles();
  const { setFieldValue } = useFormikContext<{ [key: string]: string[] }>();
  return (
    <FormikSelectField
      multiple
      name={name}
      label={label}
      initialValue={[]}
      options={options}
      MenuProps={MenuProps}
      renderValue={(selectedValues) => (
        <div className={classes.chips}>
          {selectedValues.map((selectedValue) => {
            const option = options.find((o) => o.value === selectedValue);
            return (
              <Chip
                className={classes.chip}
                tabIndex={-1}
                label={option!.label}
                deleteIcon={<CancelIcon />}
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
                onDelete={() => {
                  setFieldValue(
                    name,
                    selectedValues.filter((v) => v !== option!.value)
                  );
                }}
              />
            );
          })}
        </div>
      )}
      {...formikSelectFieldProps}
    />
  );
};
