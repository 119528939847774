import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import useMergedRef from "@react-hook/merged-ref";
import { useField } from "formik";
import { isNil } from "ramda";
import * as React from "react";

import { useInputHandlersForIME } from "../hooks/useInputHandlersForIME";

export type Props = {
  name: string;
  label: string;
  error?: boolean;
  helperText?: string;
} & TextFieldProps;

export const FormikTextField = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      name,
      label,
      error,
      helperText = "",
      onChange,
      onBlur,
      ...textFieldProps
    },
    ref
  ) => {
    const [field, meta] = useField<string | null>(name);
    const inputRef = React.useRef<HTMLInputElement | null>(null);
    const mergedRef = useMergedRef(ref, inputRef);
    const { handleBlur } = useInputHandlersForIME({
      inputRef,
      onBlur: onBlur || field.onBlur,
    });
    return (
      <TextField
        ref={mergedRef}
        id={name}
        label={label}
        value={field.value || ""}
        error={isNil(error) ? !!meta.error && meta.touched : error}
        helperText={meta.error || helperText}
        onChange={onChange || field.onChange}
        onBlur={handleBlur}
        {...textFieldProps}
      />
    );
  }
);
