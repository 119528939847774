import { useCallback, useState } from "react";

export const useOpenState = (initialState: boolean = false) => {
  const [openState, setOpenState] = useState(initialState);

  const open = useCallback(() => {
    setOpenState(true);
  }, []);

  const close = useCallback(() => {
    setOpenState(false);
  }, []);

  const change = useCallback((state: boolean) => {
    setOpenState(state);
  }, []);

  return [openState, open, close, change] as const;
};
