import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { FormControlProps } from "@material-ui/core/FormControl";
import { SelectProps } from "@material-ui/core/Select";
import { useField } from "formik";
import * as React from "react";

type Option = {
  value: string | number | string[] | undefined;
  label: string;
};

export type Props = {
  name: string;
  label: string;
  options: Option[];
  initialValue?: any;
  helperText?: string;
  renderValue?: (values: Option["value"][]) => React.ReactNode | undefined;
  multiple?: boolean;
  required?: FormControlProps["required"];
  fullWidth?: FormControlProps["fullWidth"];
  formControlVariant?: FormControlProps["variant"];
  formControlClassName?: FormControlProps["className"];
} & Omit<SelectProps, "renderValue">;

export const FormikSelectField: React.FC<Props> = ({
  name,
  label,
  options,
  initialValue = "",
  helperText = "",
  renderValue,
  multiple = false,
  required = false,
  fullWidth = false,
  formControlVariant = "standard",
  formControlClassName,
  ...selectProps
}) => {
  const [field, meta] = useField(name);
  return (
    <FormControl
      required={required}
      fullWidth={fullWidth}
      variant={formControlVariant}
      className={formControlClassName}
    >
      <InputLabel required={required}>{label}</InputLabel>
      <Select
        multiple={multiple}
        value={field.value || initialValue}
        error={!!meta.error && meta.touched}
        inputProps={{ name }}
        // @ts-ignore
        renderValue={renderValue}
        onChange={field.onChange}
        {...selectProps}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error>{meta.error || helperText}</FormHelperText>
    </FormControl>
  );
};
