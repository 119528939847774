import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import { formatNumber } from "accounting";
import { useField } from "formik";
import { isNil } from "ramda";
import * as React from "react";

import { useInputHandlersForIME } from "../hooks/useInputHandlersForIME";

import { noop } from "./utils";

type Ref = HTMLInputElement | null;

export type Props = {
  name: string;
  label: string;
  error?: boolean;
  helperText?: string;
  precision?: number;
} & TextFieldProps;

export const FormikNumberField = React.forwardRef<
  HTMLInputElement | null,
  Props
>(
  (
    {
      name,
      label,
      error,
      helperText = "",
      precision = 0,
      onBlur = noop,
      onChange = noop,
      ...textFieldProps
    },
    ref
  ) => {
    const [field, meta, { setValue }] = useField(name);
    const [inputRefState, setInputRefState] = React.useState<Ref>(null);
    const inputRef = React.useRef<HTMLInputElement | null>(null);
    const [open, setOpen] = React.useState(false);
    const errorProp = isNil(error) ? !!meta.error && meta.touched : error;
    const helperTextProp = meta.error || helperText;

    React.useEffect(() => {
      // @ts-ignore
      ref?.(inputRefState);
    }, [inputRefState, ref]);

    // NOTE: work around for https://github.com/mui-org/material-ui/issues/7960#issuecomment-509986220
    React.useEffect(() => {
      if (!inputRefState) return;
      const handler = (e: any) => e.preventDefault();
      inputRefState.addEventListener("wheel", handler);
      return () => {
        inputRefState!.removeEventListener("wheel", handler);
      };
    }, [inputRefState]);

    React.useEffect(() => {
      if (field.value !== "") return;
      setValue(null);
    }, [field.value, name, setValue]);

    const handleDummyTextFieldFocus = React.useCallback(() => {
      setOpen(true);
    }, []);

    const handleNumberFieldBlur = React.useCallback<
      NonNullable<TextFieldProps["onBlur"]>
    >(
      (...args) => {
        onBlur(...args);
        field.onBlur(...args);
        setOpen(false);
      },
      [field, onBlur]
    );

    const { handleBlur } = useInputHandlersForIME({
      inputRef,
      onBlur: handleNumberFieldBlur,
    });

    return (
      <>
        {open ? (
          <TextField
            {...textFieldProps}
            autoFocus
            type="number"
            inputRef={(ref) => {
              setInputRefState(ref);
              inputRef.current = ref;
            }}
            id={name}
            error={errorProp}
            helperText={helperTextProp}
            label={label}
            value={field.value}
            onChange={field.onChange}
            onBlur={handleBlur}
          />
        ) : (
          <TextField
            {...textFieldProps}
            error={errorProp}
            helperText={helperTextProp}
            label={label}
            // @ts-ignore
            value={
              !isNil(field.value) ? formatNumber(field.value, precision) : ""
            }
            onChange={noop}
            onBlur={noop}
            onFocus={handleDummyTextFieldFocus}
          />
        )}
      </>
    );
  }
);
