import { useField } from "formik";
import * as React from "react";

export const noop = () => {};

export const useDateField = (fieldName: string) => {
  const [field, meta, { setValue }] = useField(fieldName);

  const handleChange = React.useCallback(
    (value: Date) => {
      if (!value) return;
      setValue(value.toISOString());
    },
    [setValue]
  );

  return {
    field,
    meta,
    handleChange,
  };
};
