import { FormControlLabel, Switch } from "@material-ui/core";
import { FormControlLabelProps } from "@material-ui/core/FormControlLabel";
import { SwitchProps } from "@material-ui/core/Switch";
import { useField } from "formik";
import * as React from "react";

export type Props = {
  name: string;
  label: string;
  initialValue?: boolean;
  formControlLabelProps?: FormControlLabelProps;
} & SwitchProps;

export const FormikSwitchField: React.FC<Props> = ({
  name,
  label,
  initialValue = false,
  formControlLabelProps,
  ...switchProps
}) => {
  const [field, , { setValue }] = useField<boolean>(name);

  const handleChange = React.useCallback(
    () => setValue(!field.value),
    [field.value, setValue]
  );

  return (
    <FormControlLabel
      {...formControlLabelProps}
      label={label}
      control={
        <Switch
          id={name}
          name={name}
          value={field.value}
          defaultChecked={initialValue}
          checked={field.value}
          onChange={handleChange}
          {...switchProps}
        />
      }
    />
  );
};
