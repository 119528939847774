import { DatePicker, DatePickerProps } from "@material-ui/pickers";
import * as React from "react";

import { useDateField } from "./utils";

export type Props = {
  name: string;
  helperText?: string;
} & Omit<DatePickerProps, "value" | "onChange" | "onBlur">;

export const FormikDateField: React.FC<Props> = ({
  name,
  helperText = "",
  ...datePickerProps
}) => {
  const { field, meta, handleChange } = useDateField(name);
  return (
    <DatePicker
      name={field.name}
      value={field.value}
      error={!!meta.error && meta.touched}
      helperText={meta.error || helperText}
      onBlur={field.onBlur}
      onChange={handleChange}
      {...datePickerProps}
    />
  );
};
